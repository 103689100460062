<template>
    <b-card-code title="Service Package List">
        <b-link class="nav-link float-left" :to="{ name: 'admin-isp-service-package-add' }">
            <feather-icon icon="PlusIcon" size="21"/>
            Add New
        </b-link>
        <!-- input search -->
        <div class="custom-search d-flex justify-content-end">
            <b-form-group>
                <div class="d-flex align-items-center">
                    <label class="mr-1">Search</label>
                    <b-form-input
                        v-model="searchTerm"
                        placeholder="Search"
                        type="text"
                        class="d-inline-block"
                    />
                </div>
            </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
                enabled: true,
                externalQuery: searchTerm,
            }"
            :pagination-options="{
                enabled: true,
                perPage: pageLength,
            }"
            >
            <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <div v-if="props.column.field === 'data'" class="text-nowrap">
                    <span class="text-nowrap">ppp_limit: {{ JSON.parse(props.row.data).ppp_limit }}</span>,
                    <span class="text-nowrap">route_limit: {{ JSON.parse(props.row.data).route_limit }}</span>
                </div>

                <!-- Column: Price -->
                <div v-else-if="props.column.field === 'price'" class="text-nowrap">
                    <span class="text-nowrap">{{ props.row.currency }} {{ props.row.price }} </span>
                </div>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'status'">
                    <b-badge :variant="statusVariant(props.row.status)">
                        {{ props.row.status == 1 ? "Active" : "Inactive" }}
                    </b-badge>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                    <span>
                        <b-dropdown
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                        >
                          <template v-slot:button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="text-body align-middle mr-25"
                            />
                          </template>
                          <b-dropdown-item :to="'/admin/service-package-edit/' + props.row.id">
                            <feather-icon icon="Edit2Icon" class="mr-50"/>
                            <span>Edit</span>
                          </b-dropdown-item>
                        </b-dropdown>
                    </span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Showing 1 to </span>
                        <b-form-select
                            v-model="pageLength"
                            :options="['10', '15', '20']"
                            class="mx-1"
                            @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                    </div>
                    <div>
                        <b-pagination
                            :value="1"
                            :total-rows="props.total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="(value) => props.pageChanged({ currentPage: value })"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18"/>
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18"/>
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BLink,
} from "bootstrap-vue";
import {VueGoodTable} from "vue-good-table";
import store from "@/store";
import {ADMIN_SERVICES_PACKAGE_LIST} from "@core/services/api.js";

export default {
    components: {
        BLink,
        BCardCode,
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
    },
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: "Title",
                    field: "title",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search Title",
                    },
                },
                {
                    label: "SubTitle",
                    field: "subtitle",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search SubTitle",
                    },
                },
                {
                    label: "Benefit",
                    field: "data",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search Benefit",
                    },
                },
                {
                    label: "Price",
                    field: "price",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search Price",
                    },
                },
                {
                    label: "Status",
                    field: "status",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search Status",
                    },
                },
                {
                    label: "Action",
                    field: "action",
                },
            ],
            rows: [],
            searchTerm: "",
            isp_id: store.state.appConfig.isp_id,
        };
    },
    computed: {
        statusVariant() {
            const statusColor = {
                /* eslint-disable key-spacing */
                Current: "light-primary",
                1: "light-success",
                0: "light-danger",
                Resigned: "light-warning",
                Applied: "light-info",
                /* eslint-enable key-spacing */
            };

            return (status) => statusColor[status];
        },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true;
                return this.dir;
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false;
            return this.dir;
        },
    },
    mounted() {
        this.servicePackageList();
    },
    methods: {
        servicePackageList(ispId) {
            this.$http
                .get(process.env.VUE_APP_BASEURL + ADMIN_SERVICES_PACKAGE_LIST)
                .then((res) => {
                    this.rows = res.data.data.all_service_package;
                });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
